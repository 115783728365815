export default {
  "my": "Моє",
  "cv": "резюме",
  "description": "Я <strong class='text-white'>розробник програмного забезпечення</strong>, який шукає нові можливості для співрпаці",
  "resume": "Резюме",
  "name": "Дмитро Білостенко",
  "achievement1": "Я маю підтвердженні навички CSS на LinkedIn",
  "achievement2": "Сертифікація з курсу Freecodecamp `Responsive Web Design` і `JavaScript Algorithms and Data Structures`, сертифікація завершення школи CyberBionic systematics і програми IT Generation",
  "achievement3": "4 kyu Rank на CodeWars з більш ніж 600 завершеними завданнями в JavaScript",
  "abo": "Про",
  "utme": " мене",
  "sta": "Стати",
  "ts": "стика",
  "сore": "Основний технологічний стек",
  "tech": " та інструменти",
  "my": "Мої",
  "works": " роботи",
  "exp": "До",
  "erience": "свід",
  "ed": "Ос",
  "ucation": "віта",
  "certificates" : "Сертифікати (наведи на знак питання)",
  "repos": "Репозиторії на GitHub",
  "scores": "Статистика на Codewars",
  "ranks": "Ранг: ",
  "new": "Нові",
  "old": "Старі",
  "ukrainian": "Українська",
  "english": "Англійська",
  "details": "Детально...",
  "linkToPage": "Посилання на сторінку",
  "linkToRepo": "Посилання на репозиторій",
   // PROJECT DESCRIPTIONS
  'calculatorDescription': 'Калькулятор написаний на Javascript',
  'marvelDescription': 'Знайди свого героя Марвел та перевір його біографію.',
  'pizzaDescription': "Тільки Javascript і нічого більше =) Протестуйте функціонал: додавання та видалення замовлення піци, перетягування, зміна мови, валідація вводу в кошику і т.д.",
  'norrislDescription':  "Ласкаво просимо до колекції жартів Чака Норріса! Натисніть кнопку і приготуйтеся до сміху, сильнішого за удар Чака",
  'shoppingDescription': 'Простий лендінг, побудований на React та бібліотеці анімації GSAP.',
  'karkikDescription': 'Простий лендінг. Лише JS та CSS.',
  'stateTaskDescription': 'Проект для початківців з управління станом',
  'courseDescription': 'Лендінг з Bootstrap',
  'formsDescription': 'Форми...',
  'cutspaceDescription': 'Лендінг побудований на HTML, SCSS та JS',
  'bikcraftDescription': 'Лендінг побудований на HTML, SCSS',
  'forms2Description': 'Лендінг побудований на HTML, SCSS',
  'clubWebDescription': 'Лендінг побудований на HTML, SCSS',
  'trevlandDescription': 'Лендінг побудований на HTML, SCSS',
  'barberDescription': 'Лендінг побудований на HTML, SCSS та з JS з анімацією',
  'kettlesDescription': 'Лендінг на HTML, SCSS та з JS анімацією',
  'accounterDescription': 'Лендінг на HTML, SCSS та з JS анімацією',
  'taskManagerDescription': 'Менеджер завдань, побудований на хуках React та useReducer.',
  'reactFormDescription': 'Проста форма, побудована на React.',
  'keyCodeDescription': 'Натисніть кнопку на клавіатурі, і ви побачите її код.',
  'pizzaDescription': 'Конструктор піци з drug & drop.',
  'japanDescription': 'Лендінг про Японію.',
   // PROJECT NAMES
  'calculatorName': 'Калькулятор',
  'marvelName': 'Персонажі Marvel',
  'pizzaName': 'Сайт замовлення Pizza',
  'chukNorrisName': 'Жарти Чака Норріса',
  'shoppingName': 'Лендінг Shopping',
  'karkikName': 'Лендінг Karkik',
  'reactStateName': 'Зразок роботи на стан в React',
  'courseName': 'Лендінг сторінка курсів',
  'formsName': 'Робота з inputs, radio btns, checkboxes та інш.',
  'cutspaceName': 'Лендінг Cutspace.',
  'bikcraftName': 'Лендінг Bikсraft.',
  'forms2Name': 'Робота з inputs, radio btns, checkboxes та інш. Vol.2',
  'clubWebName': 'Лендінг ClubWeb.',
  'barberName': 'Лендінг Barber.',
  'trevlandName': 'Лендінг Trevland.',
  'kettleName': 'Лендінг Kettles.',
  'accounterName': 'Лендінг Accounter.',
  'keyCodeName': 'JavaScript keyCode.',
  'pizzaName': 'Конструктор Pizza',
  'japanName': 'Лендінг Japan ',
  'university': 'Національний університет внутрішніх справ України',
  'degree': 'Бакалавр права',
  'itGeneration': 'IT Generation:',
  'development': 'Front-End',
  'freeCodeCamp': 'FreeCodecamp:',
  'responsiveWebDesign': 'Адаптивний веб-дизайн (сертифікат може бути наданий за запитом)',
  'algorithms': 'Алгоритми та структури даних JavaScript',
  'prometheus': 'Prometheus: Основи Web UI розробки 2020',
  'udemy': 'Udemy',
  'git': 'Git: Повний курс для початківців і не тільки (сертифікат можна отримати за запитом)',
  'jsCourse': 'Повний курс JavaScript + курс React',
  'basicCSS&HTML': 'Основи CSS та HTML',
  'cyberBionic': 'CyberBionic systematics:',
  'starterJS': 'курс "JavaScript starter" (сертифікат може бути наданий за запитом)',
  'essentialJS': 'курс "JavaScript essential" (сертифікат може бути наданий за запитом)',
  'npu': 'Національна поліція України',
  'senior': 'Старший інспектор з особливих доручень Департаменту міжнародного поліцейського співробітництва (Інтерпол)',
};
